<script>
export default {
  name: "RatePageFooter",
  
  data() {
    return {
      linkIcon: require("../../assets/img/icons/link.svg"),
      downloadIcon: require("../../assets/img/icons/download.svg"),
      printIcon: require("../../assets/img/icons/print.svg")
    }
  },
  
  methods: {
    copyLink() {
      navigator.clipboard.writeText(window.location.href);
    },
  }
}
</script>

<template>
  <div class="rate-footer">
    <div class="rate-footer__container">
      <button type="button" class="rate-footer__container-btn" v-ripple @click="copyLink">
        <img :src="linkIcon" alt="copy">
      </button>
      <button type="button" class="rate-footer__container-btn" v-ripple @click="$emit('printPage')">
        <img :src="printIcon" alt="print">
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.rate-footer {
  border-radius: 24px;
  background: var(--grey-10);
  
  &__container {
    display: flex;
    
    &-btn {
      border-radius: 50%;
      background: var(--grey-20);
      margin-right: 24px;
      width: 48px;
      height: 48px;
      padding: 14px;
    }
  }
}
</style>
