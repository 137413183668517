<script>
export default {
  name: 'RatePageMenu',
  
  props: {
    tabs: {
      type: [Object, Array],
      required: true
    }
  },
  
  data() {
    return {
      activeTab: 'details',
    };
  },
  
  watch: {
    tabs: {
      handler(newVal) {
        this.tabs = newVal;
      },
      deep: true
    }
  },
  
  methods: {
    scrollToSection(sectionId) {
      this.$emit('scrollToSection', sectionId)
    }
  }
  
};
</script>

<template>
  <div>
    <nav>
      <ul class="nav-menu">
        <li
          v-for="(tab, index) in tabs"
          :key="index"
          :class="{ active: activeTab === tab.name }"
          @click="[activeTab = tab.name, scrollToSection(tab.link)]"
        >
          <a >{{ tab.title }}</a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<style scoped lang="scss">
.nav-menu {
  display: flex;
  list-style-type: none;
  padding-top: 15px;
  margin: 0;
  width: 100%;
  height: 60px;
  padding-bottom: 0;
  border-bottom: 2px solid var(--grey-50);
  
  li {
    text-align: center;
    position: relative;
    margin: 0 10px;
    cursor: pointer;
    color: var(--grey-70);
    font-family: 'Montserrat', sans-serif;
    
    @include font-description-semibold-0;

    line-height: 21.86px;
    letter-spacing: 0.01em;
    
    &:last-child {
      margin-right: 0;
    }
    
    &:first-child {
      margin-left: 0;
    }
    
    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -2px;
      width: 100%;
      height: 2px;
      background: var(--blue-50);
      opacity: 0;
      transition: opacity 0.3s;
    }
    
    &.active {
      color: var(--blue-50);
      
      &::after {
        opacity: 1;
      }
    }
    
    a {
      text-decoration: none;
      color: inherit;
      display: block;
      padding: 20px 20px;
    }
  }
}
</style>
