<script>
import RatePageStatistics from "@/components/rate-page-new/statistics/RatePageStatistics.vue";
import RatePageInfographics from "@/components/rate-page-new/statistics/RatePageInfographics.vue";
export default {
  name: "RatePageTransitInfo",

  components: {
    RatePageStatistics,
    RatePageInfographics
  },

  props: {
    rate: Object,
    statisticData: Object
  }
}
</script>

<template>
  <div class="transit-info">
    <RatePageStatistics
      :key_orig="rate.key_orig"
      class="transit-info__statistics"
      :statisticData="statisticData"
    />

    <div class="transit-info__infographics">
      <RatePageInfographics
        class="transit-info__infographics-block"
        v-if="Object.keys(statisticData).length > 0"
        :rate="rate"
        :statisticData="statisticData"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.transit-info {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;

  @media (max-width: $tablet-width) {
    flex-direction: column;
  }

  &__statistics {
    width: 30%;

    @media (max-width: $tablet-width) {
      width: 100%;
    }
  }

  &__infographics {
    width: 70%;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;

    @media (max-width: $tablet-width) {
      width: 100%;
    }

    @media (max-width: $mobile-width) {
      display: none;
    }

    &-block {
      max-width: 600px;
      position: relative;
      margin-top: 4%;
    }
  }
}
</style>