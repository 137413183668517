<script>
export default {
  name: "RatePageStatistics",

  props: {
    key_orig: String,
    statisticData: Object
  },

  data: () => ({
    active: null,
    client_id: null,
  }),

  methods: {
    getStatisticClass(data) {
      if (!data.is_visible) return 'tr-hide';
      if (data.is_result) return 'card-statistic__tr-total';
      return data.value >= 1 ? '' : 'tr-hide';
    }
  }
}
</script>

<template>
  <table class="card-statistic">
    <tr
      class="card-statistic__tr-body"
      v-for="(data, index) in statisticData.data"
      :key="index"
      :class="getStatisticClass(data)"
    >
      <td :class="(data.is_result === 0) ? '' : ' td-right '">
        {{data.caption}}
      </td>
      <td>
        {{data.value == null ? 'Нет данных' : data.value}}
      </td>
    </tr>
  </table>
</template>

<style scoped lang="scss">
.card-statistic{
  width: 100%;

  &__tr-body {
    text-align: left;
    color: var(--grey-70);
    margin: 0 $size-8;
    @include font-body-2;

    @media (max-width: $mobile-width) {
      @include font-body-3;
    }

    > td {
      padding: $size-8 0;
    }
  }

  &__tr-total {
    text-align: left;
    color: var(--grey-70);
    @include font-body-bold-2;
  }
}

.tr-hide{
  display: none;
}
</style>
