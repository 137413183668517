<script>
export default {
  name: "RatePageInfographics",

  props: {
    rate: Object,
    statisticData: Object,
  },

  data: () => ({
    steps: [],
    tz_steps: [],

    shipIcon: require('../../../assets/img/icons/ship.svg'),
    trainIcon: require('../../../assets/img/icons/train-blue.svg'),
    throughIcon: require('../../../assets/img/icons/through_service.svg'),
    autoIcon: require('../../../assets/img/icons/car-blue.svg'),
  }),

  watch: {
    rate(value) {
      if (Object.keys(this.statisticData).length > 0) {
        this.getRateSteps(value);
      }
    }
  },

  computed: {
    isNotSea() {
      return this.rate.is_direct === 1 && this.rate.is_sea === 0 ? 1 : 0;
    },
  },

  mounted() {
    if (this.rate) {
      this.getRateSteps(this.rate)
    }
  },

  methods: {
    getHeightFixClass() {
      return (this.steps.length === 3) ? '' : 'path-point-fix-block';
    },

    getWidthClass() {
      return (this.steps.length === 3) ? 'hr-width-75' : 'hr-width-50';
    },

    formatDays(value) {
      if (!value) {
        return 'нет данных';
      } else {
        const lastDigit = value % 10;
        const lastTwoDigits = value % 100;

        let declension = 'дней';

        if (lastTwoDigits < 11 || lastTwoDigits > 19) {
          if (lastDigit === 1) {
            declension = 'день';
          } else if (lastDigit >= 2 && lastDigit <= 4) {
            declension = 'дня';
          }
        }
        return `${value} ${declension}`;
      }
    },

    showitem(value){
      return ([1,2,3]).includes(value)
    },

    getRateSteps(value) {
      this.steps = this.getsteps(value.detail)
      this.tz_steps = []
      if (this.steps.length === 3 && this.rate.tranzit_time != 9999) {
        this.tz_steps.push({
          id: 1,
          tz_move: this.getTzTime('term_sea'),
          tz_wait: this.getTzTime('speed_sea_start'),
          tz_wait_caption: 'Срок выхода в море',
          type_id: value.detail[0].type_id
        })
        this.tz_steps.push({
          id: 2,
          tz_move: this.getTzTime('tz_rw'),
          tz_wait: this.getTzTime('rw_put_days'),
          tz_wait_caption: 'Постановка на жд',
          type_id: value.detail[1] ? value.detail[1].type_id : value.detail[0].type_id
        })
      } else if (this.steps.length === 2 && this.rate.tranzit_time != 9999) {
        this.tz_steps.push({
          id: 1,
          tz_move: this.statisticData.data[this.statisticData.data.length -2].value,
          tz_wait: this.getTzTime('speed_sea_start'),
          tz_wait_caption: 'Срок выхода в море',
          type_id: value.detail[0].type_id
        })
      }
    },

    getTzTime(key_name) {
      const entry = this.statisticData.data.find(item => item.name === key_name);
      return entry ? entry.value : undefined;
    },

    getsteps(value) {
      if (value) {
        value.sort((a, b) => a.service_id - b.service_id);
        let res = [];

        value.forEach((step, index) => {
          if (this.showitem(step.service_id)) {
            if (index === 0) {
              res.push({
                place: step.place_from,
                type_id: step.type_id,
                ext: step.ext_place_from === step.place_from ? "\u00A0" : step.ext_place_from
              });

              if (step.type_id === 952 || step.type_id === 519) { // прямое жд или сквозной сервис
                res.push({
                  place: this.rate.place_across_name,
                  type_id: step.type_id,
                  ext: this.rate.place_across_name === this.rate.across_place_name_ext ? "\u00A0" : this.rate.across_place_name_ext,
                  tz_wait: step.type_id === 519 ? this.getTzTime('rw_put_days') : '<span style="font-weight: 800; font-size: 14px; color: #0F0F0F">—</span>'
                });
              }

              res.push({
                place: step.place_to,
                type_id: step.type_id,
                ext: step.ext_place_to === step.place_to ? "\u00A0" : step.ext_place_to,
                tz_wait: this.getTzTime('rw_put_days')
              });
            } else {
              res.push({
                place: step.place_to,
                type_id: step.type_id,
                ext: step.ext_place_to === step.place_to ? "\u00A0" : step.ext_place_to
              });
            }
          }
        });
        return res;
      }
      return [];
    }
  }
}
</script>

<template>
  <div class="card-path">
    <hr class="card-path__hr" :class="getWidthClass()"/>
    <div class="path-points-container">
      <div class="path-point-block" :class="getHeightFixClass()" v-for="(item, index) in steps" :key="index">
        <div :class="[{'path-point__wait' : index === 1 && steps.length === 3 }, 'path-point']">
          <span class="path-point-caption" v-html="item.tz_wait"></span>
        </div>
        <div class="path-point-text">
          <span class="path-point-header">{{item.place}}</span>
          <span class="path-point-ext">{{item.ext}}</span>
        </div>
      </div>
    </div>

    <div class="path-tz-points-container">
      <div class="path-tz-point-block" v-for="item in tz_steps" :key="item.id">
        <div class="title-blue">
          <div class="path-tz-point-caption">
            <div :class="['path-tz-point-img', {'one-step' : steps.length < 3}]">
              <img
                :src="shipIcon"
                v-if="item.id === 1 && isNotSea === 0"
                alt="sea"
              >
              <img
                :src="trainIcon"
                v-if="((item.id === 1 && isNotSea === 1) || item.id === 2) && ([214, 519, 952].includes(item.type_id)) "
                alt="train"
              >
              <img
                :src="autoIcon"
                v-if="item.type_id === 1222"
                alt="auto"
              >
            </div>
          </div>
          <div class="path-tz-point-days" :class="{'one-step' : steps.length < 3}">{{item.tz_move ? item.tz_move : 'нет данных'}}</div>
        </div>
      </div>

      <div class="curve">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 19" width="100%" height="19" fill="none">
          <path d="M0.5 1.5C0.5 1.5 65.002 19 127.002 17.5C189.002 16 249.5 0.5 249.5 0.5" stroke="#305CA8"/>
        </svg>
        <div class="path-tz-point-days">
          {{formatDays(statisticData.data[statisticData.data.length -2].value)}}
        </div>
      </div>

    </div>
  </div>
</template>

<style scoped lang="scss">
.card-path{
  padding-top: $size-16;

  &__hr {
    margin: 20px auto 0;
    border: none;
    height: 1.5px;
    background: repeating-linear-gradient(
        to right,
        var(--grey-70) 0,
        var(--grey-70) 6px,
        transparent 6px,
        transparent 12px
    );
  }
}

.path-points-container{
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: auto;
  height: auto;
  position: relative;
  min-height: 30px;
}

.path-point {
  position: relative;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: var(--blue-70);
  margin: auto;
  text-align: center;
  top: -16px;

  &__wait {
    background-color: white;
    color: var(--grey-70);
    border: 2px solid var(--blue-70);
    padding-top: 1px;
    @include font-description-semibold-1;
  }
}

.path-point-text {
  color: var(--grey-70);
  display: flex;
  flex-direction: column;
  margin: auto;
  text-align: center;
}

.path-point-header{
  font-size: 14px;
  @include font-description-semibold-1;
}

.path-point-ext {
  font-size: 10px;
  font-weight: 500;
  line-height: 13px;
  letter-spacing: 0.0089em;
  text-align: center;
  min-height: 18px;
  @include font-description-1;
}

.curve {
  width: 50%;
  position: absolute;
  left: 52%;
  transform: translateX(-54%);
  margin-top: 85px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 8px 0;
}

.path-point-block{
  width: 250px;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: auto;
}

.path-tz-points-container{
  display: flex;
  flex-direction: row;
  width: 70%;
  margin-left: 15%;
  height: auto;
  position: relative;
  top: -80px;

  @media (max-width: 600px) {
    top: -74px;
  }
}

.path-tz-point-block{
  text-align: center;
  width: 100%;
}

.path-tz-point-caption {
  background-color: white;
  height: 32px;
  width: 32px;
  margin-top: -6px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.path-tz-point-days {
  color: var(--grey-70);
  background: var(--blue-10);
  padding: 4px;
  display: inline-flex;
  text-align: center;
  position: relative;
  margin: 0 auto;
  border-radius: 4px;
  @include font-description-semibold-1;

  &.one-step {
    margin-top: 8px;
  }
}

.path-tz-point-img {
  width: 32px;
  height: 32px;
  display: flex;
  position: absolute;
  background-color: white;
  top: 22%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);

  > img {
    width: 32px;
    height: auto;
  }

  &.one-step {
    top: 0;
    left: 0;
    position: absolute;
    transform: none;
    margin: 0 auto;
  }
}

.path-point-block:first-child .path-point{
  width: 10px;
  height: 10px;
}

.path-point-fix-block:before{
  content: '';
  height: 7px;
}

.path-point-block:last-child .path-point{
  width: 10px;
  height: 10px;
}

.path-point-block:first-child .path-point .path-point-caption{
  display: none;
}

.path-point-block:last-child .path-point .path-point-caption{
  display: none;
}

.path-point-caption{
  line-height: 25px;
  width: 100%;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.0089em;
  text-align: center;
}

.hr-width-50{
  width: 50%;
  margin-top: 7px;
  margin-bottom: 4px;
}

.hr-width-75{
  width: 66%;
}

.title-blue{
  color: var(--blue-70);
  font-style: normal;
}
</style>
